/******* screen-large.less *******/
/******* mixins.less 2013-1-16 *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
/******* hide-mobile.less 2013-1-16 *******/
#mobile-navi,
.togglenavigation,
.cb-mobile-access,
.cb-mobile-navigation,
.body-mobile {
  display: none;
}
.cb-mobile-navigation {
  opacity: 0;
  -o-transition: none;
  -moz-transition: none;
  -webkit-transition: none;
  transition: none;
}
.cb-mobile-invisible,
.body-non-mobile {
  display: block;
}
/******* navigation-large.less 2013-1-16 *******/
.navi,
.navi > .item,
.dock {
  display: block;
  float: left;
}
.menu {
  display: block;
}
/* LEVEL 1 */
div.sub1 {
  position: absolute;
  right: 24px;
  top: 44px ;
  z-index: 2;
  -o-transition: all 0.218s;
  -moz-transition: all 0.218s;
  -webkit-transition: all 0.218s;
  transition: all 0.218s;
}
.cb-elastic-content-small div.sub1 {
  top: -100%;
}
.sub1 > .item {
  border-right: 1px solid #60666c;
  padding: 0 10px;
}
.sub1 > .item.init {
  padding-left: 0;
}
.sub1 > .item.exit {
  border: none;
  padding-right: 0;
}
div.sub1 .menu {
  color: #000;
  line-height: 1;
}
div.sub1 .menu:hover,
div.sub1 .menu:focus {
  color: #60666c;
  text-decoration: none;
}
div.sub1 a.path {
  color: #60666c;
}
/* LEVEL 2 */
div.sub2 {
  float: right;
  margin-top: 122px;
  margin-right: 24px;
  -o-transition: all 0.218s;
  -moz-transition: all 0.218s;
  -webkit-transition: all 0.218s;
  transition: all 0.218s;
}
.cb-elastic-content-small div.sub2 {
  margin-top: 18px;
}
.sub2 > .item {
  padding-bottom: 10px;
  position: relative;
  -o-transition: all 0.218s;
  -moz-transition: all 0.218s;
  -webkit-transition: all 0.218s;
  transition: all 0.218s;
}
.sub2 > .item.init > .menu {
  padding-left: 0;
}
.sub2 > .item.exit > .menu {
  border: none;
  padding-right: 0;
}
.sub2 > .item:hover > .menu,
.sub2 > .item:focus > .menu {
  color: #820019;
}
.cb-elastic-content-small .sub2 > .item {
  padding-bottom: 17px;
}
div.sub2 .menu {
  text-transform: uppercase;
  color: #000;
  line-height: 1;
  border-right: 1px solid #60666c;
  padding: 0 10px;
}
div.sub2 .menu:hover,
div.sub2 .menu:focus {
  text-decoration: none;
}
div.sub2 a.path {
  color: #820019;
}
/* LEVEL 3 */
div.sub3 {
  position: absolute;
  top: 100%;
  left: 50%;
  width: 120px;
  margin-left: -60px;
  z-index: 2;
  background: url(/images/sub3.png) repeat 0 0;
  padding: 3px 0;
  box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.5);
  display: none;
  border-top: 1px solid transparent;
}
.cb-elastic-content-small div.sub3 {
  border-top: 1px solid #949a9e;
}
div.sub2 > .item:hover div.sub3 {
  display: block;
}
.sub3 > .item {
  width: 100%;
}
div.sub3 .menu {
  font-size: 14px;
  padding: 6px 10px 6px 20px;
  border: none;
  background: url(/images/sub3-menu.png) no-repeat 8px 0.85em;
}
div.sub3 .menu:hover,
div.sub3 .menu:focus {
  color: #820019;
}
div.sub3 a.path {
  color: #820019;
}
/******* layout-large.less 2013-1-16 *******/
.desk {
  max-width: 96%;
}
#home {
  width: 220px;
  margin: 46px 0 62px 62px;
  -o-transition: all 0.218s;
  -moz-transition: all 0.218s;
  -webkit-transition: all 0.218s;
  transition: all 0.218s;
}
.cb-elastic-content-small #home {
  width: 168px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.cb-layout2 #head {
  margin-top: 74px;
}
#cb-elastic-container {
  position: fixed;
}
#multimood {
  margin-top: 148px;
}
.wave {
  width: auto;
  top: 80px;
  -o-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.cb-elastic-content-small .wave {
  left: -376px;
  top: -130px;
}
.cb-layout1 .moodshadow {
  display: block;
}
h1 {
  padding: 0;
}
#root div.north div.unit div.head h2 {
  font-size: 52px;
}
.bookingmaskwidth {
  width: 1400px;
  left: 50%;
  margin-left: -700px;
}
.bookingmaskwidth.cb-navi-fixed {
  position: fixed;
  top: 0;
}
@media only screen and (max-width: 1447px) {
  .bookingmaskwidth {
    width: 96%;
    left: 2%;
    margin-left: 0;
  }
}
.area {
  width: 100%;
}
.area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area h2,
.area .foot {
  margin-right: 1.92307692%;
  margin-left: 1.92307692%;
}
.area .part,
.area > .grid table {
  margin-right: 1.92307692%;
  margin-left: 1.92307692%;
  width: 96.15384615%;
}
.area .tiny {
  width: 46.15384615%;
}
.area > .slim {
  width: 50%;
}
.area > .slim h2,
.area > .slim .foot,
.area > .slim .part,
.area > .slim.grid table {
  margin-right: 3.84615385%;
  margin-left: 3.84615385%;
}
.area > .slim .part,
.area > .slim.grid table {
  width: 92.30769231%;
}
.area > .slim .tiny {
  width: 92.30769231%;
}
.cb-layout2 .main {
  width: 100%;
}
.cb-layout2 .main > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-layout2 .main h2,
.cb-layout2 .main .foot {
  margin-right: 1.69491525%;
  margin-left: 1.69491525%;
}
.cb-layout2 .main .part,
.cb-layout2 .main > .grid table {
  margin-right: 1.69491525%;
  margin-left: 1.69491525%;
  width: 96.61016949%;
}
.cb-layout2 .main .tiny {
  width: 46.61016949%;
}
.cb-layout2 .main > .slim {
  width: 50%;
}
.cb-layout2 .main > .slim h2,
.cb-layout2 .main > .slim .foot,
.cb-layout2 .main > .slim .part,
.cb-layout2 .main > .slim.grid table {
  margin-right: 3.38983051%;
  margin-left: 3.38983051%;
}
.cb-layout2 .main > .slim .part,
.cb-layout2 .main > .slim.grid table {
  width: 93.22033898%;
}
.cb-layout2 .main > .slim .tiny {
  width: 93.22033898%;
}
.unit.form .tile .name,
.unit a.capt {
  float: left;
  width: 33%;
}
.unit.form .tile .chop,
.unit.form .tile textarea,
.unit.form .tile input.text,
.unit.form .tile .ctrl,
.unit input.capt {
  float: right;
  width: 63%;
}
/*.unit {
  margin-top: 10px;
  margin-bottom: 10px;
}*/
.main {
  width: 104%;
  margin-left: -2%;
}
.cb-layout2 .main {
  width: 71.95121951%;
  margin-left: -1.2195122%;
}
.base,
.south {
  width: 102.43902439%;
  margin-left: -1.2195122%;
}
#root div.farnorth {
  width: 96%;
  margin-left: 2%;
  margin-right: 2%;
}
div.base div.unit,
div.south div.unit {
  margin-left: 1.19047619%;
  margin-right: 1.19047619%;
}
div.base div.unit.wide,
div.south div.unit.wide {
  width: 97.61904762%;
}
div.base div.unit.wide div.head h2,
div.south div.unit.wide div.head h2,
div.base div.unit.wide div.part,
div.south div.unit.wide div.part {
  margin-left: 3.75782881%;
  margin-right: 3.75782881%;
}
div.base div.unit.wide div.tall,
div.south div.unit.wide div.tall {
  width: 92.48434238%;
}
div.base div.unit.wide div.tiny,
div.south div.unit.wide div.tiny {
  width: 42.48434238%;
}
div.base div.unit.slim,
div.south div.unit.slim {
  width: 47.61904762%;
}
div.base div.unit.slim div.head h2,
div.south div.unit.slim div.head h2,
div.base div.unit.slim div.part,
div.south div.unit.slim div.part {
  margin-left: 7.92951542%;
  margin-right: 7.92951542%;
}
div.base div.unit.slim div.part,
div.south div.unit.slim div.part {
  width: 84.14096916%;
}
#disp {
  left: 50%;
  margin-left: -175px;
  width: 350px;
  font-size: 15px;
  font-size: 1.5rem;
  top: 120px !important;
}
#disp.zoom {
  left: 50%;
  margin-left: -385px;
  width: 770px;
}
#disp.tube {
  left: 50%;
  margin-left: -330px;
  width: 660px;
}
#disp.site > .body {
  overflow: auto;
  max-height: 400px;
}
/*#disp.site li,
div.cb-sitemap li {
  .font-size(14); 
}*/
h1,
h2 {
  overflow-wrap: normal;
  word-wrap: normal;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}
/*# sourceMappingURL=./screen-large.css.map */